import { useEffect } from 'react';
import './AppStorePages.css';

const AppStoresPage = () => {

  const appStoreUrl = 'https://apps.apple.com/au/app/zimi/id1434488884';
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=au.com.gsminnovations.zimi';

  const handleAppStoreClick = () => {
    window.location.href = appStoreUrl;
  };

  const handlePlayStoreClick = () => {
    window.location.href = playStoreUrl;
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let appStoreUrlToRedirect = '';

    if (userAgent.indexOf('Android') !== -1) {
      appStoreUrlToRedirect = playStoreUrl;
    } else if (userAgent.indexOf('like Mac') !== -1) {
      appStoreUrlToRedirect = appStoreUrl;
    }

    if (appStoreUrlToRedirect) {
      window.location.replace(appStoreUrlToRedirect);
    }
  }, []);

  return (
    <div className="app-stores-page">
      <h1> Get the Zimi app</h1>
      <p> Please download the Zimi app for your device</p>
      <div className="app-store-buttons">
        <img src="/images/btn-app-store.svg" alt="App Store" onClick={handleAppStoreClick} />
      </div>
      <div className="app-store-buttons">
        <img src="/images/btn-google-play.svg" alt="Play Store" onClick={handlePlayStoreClick} />
      </div>
    </div>
  );
};

export default AppStoresPage;